@import '../common/mixin.scss';

.yt-wrapper {
    .react-player__preview {
        border-radius: 1em;
    }

    .backdrop {
        @include create-backdrop;

        .close {
            color: white;
            position: fixed;
            right: 1em;
            top: 1em;
            font-size: 2em;
        }

        .video {
            width: 100vw;
            height: 60vw;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
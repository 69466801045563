@import '../common/mixin.scss';

.footer-bar {
  background-color: black;
  color: white;
  padding: 4rem 8rem 1rem 8rem;
  display: flex;
  justify-content: space-between;

  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  h3 {
    font-weight: 500;
  }

  .navs {
    display: flex;
    flex-direction: column;

    a {
      color: white;
      text-decoration: none;
      font-size: 1.3em;

      img {
        vertical-align: middle;
        height: 1.5em;
        padding: 0.25em 0.5em 0.25em 0;
      }
    }

    &:last-child {
      a {
        margin-bottom: .5rem;
      }
    }

    .white-cta {
      padding: 0.7em 1em 0.7em 1em;
      background-color: white;
      color: black;
      border-radius: 0.2em;
      font-weight: 600;
      display: block;
    }
  }

  .lower {
    margin-top: auto;
    padding: 1rem;
    text-align: center;

    >div {
      a {
        img {
          width: 4em;
          margin: 2em;
        }
      }
    }

    h1 {
      margin: 0;
    }

    h2 {
      font-size: 1.4em;
      margin: 0;
      font-weight: 200;
    }
  }

  @media #{$small-screens} {
    padding: 0;
    text-align: center;
    display: block;
    font-size: .8rem;

    .lower {
      padding: 1em;
    }
  }
}
@import '../common/mixin.scss';

.scr12 {
  .pp-cir {
    position: relative;

    .circ {
      border-radius: 50%;
      border: 2px solid white;
      width: 2em;
      height: 2em;
      position: absolute;
      top: -1em;

      &:last-child {
        right: -4em;
      }

      &:first-child {
        left: -4em;
      }
    }

    .pp {
      border-color: white;
      border-width: 2px;
    }

  }

  .screen12-main {
    font-size: 0.5em;

    @media #{$small-screens} {
      font-size: .2em;
    }

    .heading {
      text-align: center;
      color: white;
      text-align: center;
      background-image: url("../assets/media/screen-12/bg.png");
      background-position: center;
      background-size: cover;
      padding: 5em;

      .heading-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 12em;
          margin: 1em 0;
          font-weight: 100;
          letter-spacing: 2px;
        }

        >div {
          width: 45%;
          margin: 3em;

          h2 {
            font-size: 7em;
          }
        }

      }
    }

    .screen12-content {
      font-size: 2em;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 7.5em;
      }

      .first-div {
        text-align: center;
        background-image: url("../assets/media/screen-12/div1bg_.png");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10em 0;
        width: auto;
        margin: 0;
      }

      .second-div {
        margin: 4em 0;

        h1 {
          color: #204191;
          font-size: 4em;
          margin: 0;
        }

        img {
          margin: 2em 0;
          width: 40%;
        }

        p {
          margin: 1em 0;
          width: 40%;
          font-size: 2.5em;
          font-weight: lighter;
        }

        @media #{$small-screens} {

          img,
          p {
            width: 60%;
          }
        }
      }

      .footer-bar {
        background-color: #f4693a;
        color: white;
        padding: 3em 0;

        >div {
          display: flex;
          flex-direction: column;

          button {
            cursor: pointer;
            margin: 0.5em;
            width: 50%;
            margin-left: auto;
            background-color: #204191;
            border: 0;
            border-radius: .25em;
            color: white;
            font-size: 4em;
            font-weight: 600;
            padding: .5em;
          }
        }
      }
    }

    @media #{$small-screens} {
      .screen12-content {
        font-size: 3em;
      }
    }

  }
}
@import "../common/mixin.scss";

.top-images {
  background-image: url("../assets/media/plans/bg.png");
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: right top;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  padding: 3rem;
  position: absolute;
  right: -3em;
  top: -40em;

  img {
    height: intrinsic;
  }
}

.plans-card {
  border: 3px solid whitesmoke;
  width: 95%;
  margin: 40em auto 2rem auto;
  position: relative;

  .plans-heading {
    margin: 12em 2em 2em 5em;

    >h1 {
      font-size: 5em;
      margin: 0;
      font-weight: bolder;
    }
  }

  .plans-content {
    margin: 1rem 0;
    text-align: center;

    .column-valus {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 1rem 0;

      .col-1 {
        width: 40em;
        text-align: left;
      }

      div {
        text-align: center;
      }

      h1 {
        margin: 0;
      }

      img {
        height: 2em;
      }
    }
  }
}

@media #{$tab-screens} {
  .plans-card {
    margin: 30em auto 2rem auto;

    .top-images {
      top: -30em;
    }
  }
}

@media #{$small-screens} {
  .plans-card {
    margin: 12em auto 2rem auto;

    .top-images {
      top: -12em;
    }

    .plans-heading {
      font-size: 0.5em;
      margin: 8em 0 0 4em;
      font-weight: bolder;
    }
  }
}
@import "../../common/mixin.scss";

.drawer {
  .backdrop {
    display: none;
    opacity: 0;
    @include create-backdrop;
    z-index: 10;
  }

  a.ham {
    width: 1em;
    position: absolute;
    left: 0;
    top: 5.5rem;

    hr {
      padding: 0;
      margin: 1px;
      width: inherit;
    }
  }

  .drawer-main {
    z-index: 11;
    min-width: 24rem;
    background-color: black;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    transition: transform 0.3s ease-out;
    transform: translateX(-100%);
    padding: 2em;

    .clicks {
      margin-top: 3em;

      >a {
        color: white;
        font-size: 2em;
        margin: 0.5em 0;
        text-decoration: none;
        display: block;
        font-weight: 100;
      }
    }

    .contacts {
      .contact {
        margin: 0.5em 0;
        display: flex;

        >p {
          font-size: 1.5em;
          margin: 0;
        }

        >img {
          width: 1.75em;
          margin-right: 1em;
        }

        .white-cta {
          padding: 0.7em 1em 0.7em 1em;
          background-color: white;
          color: black;
          border-radius: 0.2em;
          font-weight: 600;
          display: block;
        }
      }
    }

    .lower {
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 1.5em;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
      text-align: center;

      h2 {
        font-size: 1em;
        margin-top: 3em;
      }

      a {
        margin: 0 0.75em;

        >img {
          height: 3em;
        }
      }

      .copyright {
        font-size: 1.5em;
      }
    }
  }

  &.open {
    .backdrop {
      display: block;
      opacity: 1;
    }

    .drawer-main {
      transform: translate(0%);
    }
  }
}
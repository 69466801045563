@import "../common/mixin.scss";

.main_publishing {
  border: 3px solid whitesmoke;
  display: flex;
  justify-content: space-evenly;
  padding: 4rem 0;
  margin: auto;
  width: 100vw;
  font-size: 1rem;

  .scroller {
    position: absolute;
    bottom: -.6em;
    left: 0;
    width: 100%;
    background-image: linear-gradient(0, #fff, #fff, transparent);
    height: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    >div {
      border: 1em solid transparent;
      width: 0;
      height: 0;
      margin: 0.4em auto;

      &:first-child {
        border-bottom-color: black;
      }

      &:last-child {
        border-top-color: black;
      }
    }
  }

  .column {
    width: 28%;
    height: 55rem;
    overflow: hidden;
    position: relative;

    @media #{$small-screens} {
      position: unset;
    }

    >h1 {
      font-size: 3em;
    }

    .markup {
      text-align: left;
      overflow: auto;
      height: calc(100% - 7rem);
      scroll-snap-type: y mandatory;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      .card_publishing {
        margin-bottom: 2em;
        scroll-snap-align: start;

        h1 {
          margin-top: 0.5rem;
          margin-bottom: 0;
          font-size: 1.6em;
        }

        span {
          font-size: 1.6em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        a {
          font-weight: bold;
          color: #f4693a;
          font-size: 1.6em;
        }

        &.article img {
          width: 100%;
          filter: grayscale(1);
        }

        @include yt-video-mixin(30em);

        .yt-wrapper {
          margin: auto;
        }
      }
    }
  }
}

@media #{$small-screens} {
  .main_publishing {
    display: flex;
    padding: 0;
    flex-direction: column;
    border: none;

    .column {
      font-size: 0.8rem;
      height: auto;
      border: 3px solid whitesmoke;
      border-radius: 1rem;
      width: 90%;
      margin: 0 auto 0.5rem auto;
      transition: all 0.5s linear;

      >h1 {
        font-size: 2.5em;
        text-align: center;
      }

      .markup {
        height: 0;
        transition: height 0.5s linear;
        padding: 0 1em;

      }

      &.open {
        width: 100%;
        border-radius: 0;
        border: 0px solid whitesmoke;
        border-top: 3px solid whitesmoke;
        border-bottom: 3px solid whitesmoke;


      }

      .card_publishing {
        width: auto;
        height: auto;
        font-size: 1.2rem;

        .yt-video {
          @include yt-size-mixin(33em);
          margin: auto;

          ~h1 {
            width: 33em;
            margin: auto;
          }
        }
      }
    }
  }
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 2;
}
.popup_inner {
  position: fixed;
  width: 100%;
  top: 25%;
  margin: auto;
  
  z-index: 32;
}
.popup_inner > div{
  background: white;
  margin: 0 auto;
  padding: 1em;
  max-width: 60em;
}
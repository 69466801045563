@import '../common/mixin.scss';


.acc-or {
  display: flex;
  flex-direction: column;

  >* {
    margin: auto;
  }

  .accordian__section {
    display: flex;
    flex-direction: column;
    width: 80%;

    @media #{$small-screens} {
      width: 100%;
    }
  }

  .accordian {
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: .5em 0;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;

    &:hover {
      background-color: #ccc;
    }

    @media #{$small-screens} {
      &:hover {
        background-color: white;
      }
    }
  }

  .accordian__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1em;
    margin-bottom: 1em;
    color: #6D6B6B;
  }

  .accordian__icon {
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .accordian__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    font-size: 1em;
  }

  .accordian__text1 {
    font-weight: 400;
    font-size: 1em;
    padding: 1em 0;
    text-align: justify;
  }

  .bor-hr {
    width: 80%;
    background-color: #6D6B6B;
    min-height: 1px;
    margin: .5em auto;

    @media #{$small-screens} {
      width: 100%;
    }
  }
}
@import '../common/mixin.scss';

.nav-bar-ht {
  height: 5rem;
}

.nav-bar {
  font-size: 1.2em;
  color: #000000;
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  z-index: 5;

  //position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  box-shadow: 0px 2px 2px whitesmoke;

  &.no-bg {
    background-color: transparent;
    box-shadow: none;
  }

  a.logo img {
    height: 5em;
  }

  .navs {
    align-self: center;
    display: flex;
    white-space: nowrap;

    a {
      margin: 0 0.5rem;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      align-self: center;
      text-decoration: none;
      color: black;
      font-size: 1.5em;

      &.order-wiom {
        font-size: .7em;
      }

      &.selected {
        &.pdo {
          color: #F4693A;
        }

        &.partner {
          color: #0839FB;
        }
      }
    }
  }
}

.header-button {
  height: 5rem;
  width: 21rem;
  background: white;
  color: #d92b90;
  border-radius: 100px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}

.icon-button {
  display: flex;
  align-items: center;
  padding: 0.1em 1.0em;
  background: white;
  color: black; /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.icon-button i {
  margin-right: 10px; /* Adjust the space between the icon and text */
}

.header-content-div{
  display: flex;
  flex-direction: row;
  margin: 1em;
  gap: 2em;
}



@media #{$small-screens} {
  .nav-bar {
    display: flex;
    padding: 1.5rem;
    background-color: white;
    width: 100%;

    a.logo {
      display: inline-flex;
    }

    a.logo img {
      margin-top: 2em;
      height: 7em;
    }
    .navs {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 1rem;

      a {
        width: fit-content;
        font-weight: bold;
        text-align: center;
        font-size: 1em;
        cursor: pointer;
        white-space: nowrap;
      }

      .order-wiom {
        display: none;
      }
    }
  }
  .header-content-div{
    flex-direction: column;
    margin-top: 0;
    margin-right: 3em;
    gap: 1em;
  }
}

.floating-button {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  a button {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
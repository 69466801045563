@import '../common/mixin.scss';

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.floating-button {
  top: 20%;
}

.status-certification {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 640px;
  height: max-content;

  .check-status {
    max-width: 50%;
    margin: 0px auto;

    .input:focus {
      outline: none;
    }

    .check-now {
      padding: 0.8em;
      text-decoration: none;
      box-shadow: none;
      font-weight: 800;
      border: none;
      margin-top: 20px;
      border-radius: 3px;
      color: white;
      background: linear-gradient(to bottom, #f4693a, #ef4f82);
    }
  }

  .title {
    text-align: left;
    font-size: 4em;
    font-weight: 800;
    display: block;
  }

  .or {
    text-align: center;
    font-size: 1em;
    display: block;
  }

  .input {
    font-size: 3em;
    padding: 0.8em 1em 0.8em 1em;
    font-weight: 600;
  }

  .congrats-container {
    .congrats-image {
      width: 8em;
    }

    .congrats-title {
      font-size: 4em;
      font-weight: 800;
      color: #f4693a;
    }

    .congrats-subtitle {
      font-size: 4em;
      font-weight: 800;
    }

    .congrats-cta {
      padding: 10px 30px 10px 30px;
      text-decoration: none;
      box-shadow: none;
      font-weight: 800;
      border: none;
      margin-top: 10px;
      border-radius: 3px;
      color: white;
      font-size: 2em;
      background: linear-gradient(to bottom, #f4693a, #ef4f82);
    }
  }

  .underprocess-container {
    .underprocess-image {
      width: 8em;
    }

    .underprocess-subtitle {
      font-size: 4em;
      font-weight: 800;
    }

    .underprocess-subtext {
      font-size: 3em;
      margin-top: 30px;
    }
  }

  .notregistered {
    .image {
      width: 8em;
    }

    .subtitle {
      font-size: 4em;
      font-weight: 800;
    }

    .subtext {
      font-size: 3em;
      margin-top: 70px;
    }

    .cta {
      padding: 10px 30px 10px 30px;
      text-decoration: none;
      box-shadow: none;
      font-weight: 800;
      border: none;
      margin-top: 10px;
      border-radius: 3px;
      color: white;
      font-size: 2em;
      background: linear-gradient(to bottom, #f4693a, #ef4f82);
    }
  }

  .preview-container {
    .preview-image-container {
      .preview-image-desktop {
        display: block;
        width: 874px;
        height: 625px;
      }

      .preview-image-mobile {
        display: none;
      }
    }

    .preview-cta {
      padding: 10px 30px 10px 30px;
      text-decoration: none;
      box-shadow: none;
      font-weight: 600;
      border: none;
      margin-top: 10px;
      border-radius: 3px;
      color: white;
      font-size: 1.5em;
      background: linear-gradient(to bottom, #f4693a, #ef4f82);
    }
  }
}


@media only screen and (max-width: 700px) {
  .status-certification {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 640px;

    .check-status {
      max-width: 90%;
      margin: 0px auto;

      .input {
        font-size: 2em;
        border: solid 2px black;
      }

      .input:focus {
        outline: none;
      }

      .title {
        font-size: 3em;
      }

      .check-now {
        font-size: 1.8em;
        font-weight: 600;
      }
    }

    .title {
      text-align: left;
      font-size: 4em;
      font-weight: 800;
      display: block;
    }

    .or {
      text-align: center;
      font-size: 1em;
      display: block;
    }

    .input {
      font-size: 3em;
      padding: 0.8em 1em 0.8em 1em;
      font-weight: 600;
    }

    .congrats-container {

      .congrats-title {
        font-size: 3em;
      }

      .congrats-subtitle {
        font-size: 2em;
      }

      .congrats-cta {
        font-weight: 400;
      }
    }

    .preview-container {
      .preview-image-container {
        margin: 0px auto;
        text-align: center;

        .preview-image-desktop {
          display: none;
        }

        .preview-image-mobile {
          display: block;
          width: 350px;
          height: 250px;
          margin: 0px auto;
        }
      }

      .preview-cta {
        font-weight: 400;
      }
    }

    .notregistered {
      .image {
        width: 8em;
      }

      .subtitle {
        font-size: 2.5em;
        font-weight: 800;
      }

      .subtext {
        font-size: 2em;
        margin-top: 70px;
      }

      .cta {
        padding: 10px 30px 10px 30px;
        text-decoration: none;
        box-shadow: none;
        font-weight: 600;
        border: none;
        margin-top: 10px;
        border-radius: 3px;
        color: white;
        font-size: 1.8em;
        background: linear-gradient(to bottom, #f4693a, #ef4f82);
      }
    }

    .underprocess-container {
      .underprocess-subtitle {
        font-size: 3em;
      }

      .underprocess-subtext {
        font-size: 2em;
      }
    }
  }

  .order-wiom {}
}
@import 'common/mixin.scss';


// @media only screen and (max-width: 1400px) and (min-width: 1281px) {
//   html {
//     font-size: 80%;
//   }
// }

// @media #{$medium-screens} {
//   html {
//     font-size: 65%;
//   }
// }

// @media only screen and (max-width: 900px) and (min-width: 701px) {
//   html {
//     font-size: 50%;
//   }
// }

// @media only screen and (max-width: 700px) and (min-width: 631px) {
//   html {
//     font-size: 45%;
//   }
// }

// @media only screen and (max-width: 630px) and (min-width: 541px) {
//   html {
//     font-size: 40%;
//   }
// }

/* width */
::-webkit-scrollbar {
  width: 5px;
  @media #{$small-screens} {
    width: 2px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b9b9b96b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
}

.App {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
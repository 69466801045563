@import '../common/mixin.scss';


.circle {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  border: 1px solid black;
}

.center-text {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  font-size: 1.8rem;

  .heading {
    position: absolute;
    height: 80vh;

    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .head-back {
      filter: grayscale(100%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .heading-line {
    text-align: center;
    width: auto;
    height: 70vh;
    background: transparent;
    z-index: 1;
    justify-content: center;
    flex-direction: column;
    display: flex;

    h1 {
      margin: 0;
      color: black;
    }
  }

  .pdo-arrow {
    z-index: 20;
    position: relative;
    bottom: 20vh;
    left: 50%;
    transform: translateX(-10%);

    img {
      width: 5rem;
    }
  }


  @media #{$tab-screens} {
    .heading {
      height: 30em !important;
    }

    .heading-line {
      height: 27em !important;
    }

    .pdo-arrow {
      display: none;
    }
  }

  &.pdo {
    .heading {
      height: 100vh;
    }

    .heading-line {
      height: 86vh;
    }
  }

  .content {
    background: transparent;
    z-index: 1;

    >div {
      margin: auto;
    }

    .card {
      border: 3px solid whitesmoke;
      border-radius: 5px;
      text-align: center;
      padding: 4rem 0;
      margin: 2rem auto;
      width: 95vw;
      background-color: white;

      &.india {
        .head h1 b {
          color: black;
        }
      }

      .head {
        display: flex;
        justify-content: space-between;
        padding: 0 6rem;

        h1 {
          font-size: 2.5em;
          margin: 0;
        }
      }

      &:first-child .head h1 {
        margin: auto;
      }

      &.no-border {
        border: none;
        position: relative;
      }

      .ext-border {
        border: 3px solid whitesmoke;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: -3px;
        width: 100%;
        bottom: 16rem;
      }

      .details {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 4rem 0 0 0;

        @include yt-video-mixin(25em);

        .yt-wrapper {
          text-align: left;

          .yt-video-sub {
            .g-cir-img {
              margin: 0.5em 0.25em 0.5em 0;
              @include g-cir-img-mixin(1.5em, 2em)
            }

            >div {
              margin: auto 0.5rem;
              color: rgb(109, 107, 107);

              span {
                color: black;
                font-weight: bold;
              }
            }
          }
        }

        .seperator {
          width: 10%;
          margin: 5em 0;
        }

        &.start {
          justify-content: flex-start;
          border: 3px solid whitesmoke;
          border-radius: 5px;
          padding: 4rem;
          margin: 4em 0 0 0;
          position: relative;
          background-color: white;

          @include yt-video-mixin(20em);

          .yt-wrapper {
            &:first-child {
              margin-right: 7em;
            }

            .yt-video-sub {
              display: flex;
              margin-top: .25em;
              font-size: 1em;
            }
          }

          .no_1_sticker {
            position: absolute;
            right: 0;
            bottom: 2em;
            transform: translate(30%, 30%);
          }
        }

        .list-style {
          .list-h {
            font-weight: bold;
          }

          ul {
            color: #6D6B6B;
          }
        }
      }
    }
  }
}



@media #{$small-screens} {

  .home {
    .heading {
      height: 30vh;
    }

    .heading-line {
      font-size: .8rem;
      height: 30vh;
    }

    .content {
      .card {
        padding: 1em 0;

        &.no-border {
          border: 3px solid whitesmoke;
        }

        .head {
          padding: 0 1rem;

          h1 {
            font-size: 1.4em;
            text-align: left;
          }
        }

        &:first-child .head h1 {
          margin: unset;
        }

        .details {
          margin: auto;
          flex-direction: row-reverse;
          padding: 0 1rem !important;
          font-size: .8rem;
          margin: 2em auto 2em auto;

          &:not(.start) {
            .yt-wrapper {

              &:first-child {
                margin-left: auto;
              }

              &:last-child {
                margin-right: auto;
                position: relative;
                top: -6em;
                height: 14em;
              }
            }

            .seperator {
              position: relative;
              top: -2em;
              right: -2em;
            }
          }

          .seperator {
            width: 30%;
            margin: auto;
          }

          &.start {
            border: none;
            background-color: transparent;
            justify-content: center;

            @include yt-video-mixin(44em);

            .yt-wrapper {
              margin: auto auto 2em auto !important;

              .yt-video-sub {
                font-size: 2.1em;
              }
            }
          }

          .list-style {
            font-size: 1.3em;
          }
        }

        &.india {
          font-size: 1rem;

          .head h1 {
            text-align: center;
          }
        }
      }
    }
  }
}



.slider {
  width: 100%;
  height: 17em;
  text-align: center;
  overflow: hidden;

  .dotss {
    //border: 1px solid whitesmoke;
    color: whitesmoke;
    text-decoration: none;
    border-radius: 50%;
    position: relative;
    bottom: 2em;
    margin: 0 .25em;
    font-size: 1em;
  }

  .slides {
    display: flex;
    height: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .slides::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .slides>div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    margin-right: 1em;
    border-radius: .5em;
    background: #eee;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
  }

  .slides>div:target {
    /*   transform: scale(0.8); */
  }

  .author-info {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 0.75rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

  .author-info a {
    color: white;
  }

  img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(1);
  }

  .slider>a {
    display: inline-flex;
    width: 1.5rem;
    height: 1.5rem;
    background: white;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0 0.5rem 0;
    position: relative;
  }

  .slider>a:active {
    top: 1px;
  }

  .slider>a:focus {
    background: #000;
  }

  /* Don't need button navigation */
  @supports (scroll-snap-type) {
    .slider>a {
      display: none;
    }
  }
}
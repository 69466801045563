@import "../../common/mixin.scss";

.expandtable-main {
  padding: 3rem;

  .main-table {
    width: 100%;

    th h1 {
      font-size: 2em;
    }

    @media #{$tab-screens} {
      th h1 {
        font-size: 1.2em;
      }
    }

    td {
      display: flex;
      align-items: center;
      margin: 0;

      th {
        width: 100%;

        @media #{$tab-screens} {
          &.plan-title {
            width: 300%;
          }
        }

        .price-button {
          color: white;
          cursor: pointer;
          border: 0;
          min-width: 12em;
          text-align: center;
          font-size: 1em;

          @media #{$small-screens} {
            width: auto;
            padding: .25em 1em;
            min-width: 7.2em;
          }

          &:hover {
            filter: grayscale(20%);
          }
        }
      }

      .plan-title {
        text-align: left;
      }

      tr {
        width: 100%;
        text-align: center;
        font-size: 1.1em;

        >img {
          width: 3em;
        }

        @media #{$tab-screens} {
          &.plan-title {
            width: 300%;
          }
        }

        @media #{$small-screens} {
          >img {
            width: 2em;
          }
        }
      }
    }
  }
}

.plan-contact-us {
  margin: auto 6em auto auto;
  text-align: right;
}

@media #{$small-screens} {
  .expandtable-main {
    .plan-title {
      text-align: left;

      >h1 {
        font-size: 1em;
      }
    }
  }

  .plan-contact-us {
    font-size: 1.5em;
    margin: auto 1em auto auto;
    ;
  }

}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$smallest-screens: "only screen and (max-width: 320px)";
$very-small-screens: "only screen and (max-width: 360px)";
$mid-small-screens: "only screen and (max-width: 420px)";
$small-screens: "only screen and (max-width: 540px)";
$tab-screens: "only screen and (max-width: 900px)";
$medium-screens: "only screen and (max-width: 1280px)";
$big-screens: "only screen and (min-width: 1281px)";
$very-big-screens: "only screen and (min-width: 1501px)";

.visible-in-small {
  display: none !important;
}

@media #{$small-screens} {
  .hide-in-small {
    display: none !important;
  }

  .visible-in-small {
    display: block !important;
  }
}

a {
  color: #0839fb;
}

button,
a.button {
  text-decoration: none !important;

  &.primary {
    border-radius: 5px;
    border: 0;
    padding: 0.9em;
    box-shadow: 1px 1px 4px #9e9e9e;
    outline: none;
    font-size: 1.8em;
    font-weight: bold;
  }

  &.pink {
    color: white;
    // background-image: linear-gradient(180deg, #f4693a, #ef4f82);
    background-color: #D92B90;
    font-family:'Noto Sans', 'sans-serif';
  }

  &.blue {
    color: white;
    background-image: linear-gradient(180deg, #214294, #092262);
  }

  &.noshadow {
    box-shadow: none;
  }
}

@mixin create-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

@mixin yt-size-mixin($size) {
  width: $size;
  height: $size * 0.6;

  img {
    width: $size * 0.2 !important;
  }
}

@mixin yt-video-mixin($size) {
  .yt-wrapper {
    width: $size;
    font-size: 1em;

    .yt-video {
      @include yt-size-mixin($size);
    }

    .yt-video-sub {
      width: 100%;
    }
  }
}

@mixin g-cir-img-mixin($size, $imgSize) {
  background-color: #6d6b6b;
  padding: 1em;
  border-radius: 50%;
  height: $size;
  width: $size;
  display: flex;
  justify-content: center;

  img {
    align-self: center;
    height: $imgSize;
  }
}

.w-50p {
  width: 50%;
}

.w-40p {
  width: 40%;
}

.cardify {
  padding: 0.67em;
  border: 3px solid whitesmoke;
  border-radius: 1rem;
}
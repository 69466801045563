@import '../common/mixin.scss';

.form-main {
  display: flex;
  flex-direction: column;

  .form-bg-img {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }

  .form-main-heading {
    text-align: center;
    margin: 2rem 0;

    >h1 {
      font-size: 4.5em;
      margin: 0;

      @media #{$tab-screens} {
        font-size: 3em;
      }
    }
  }

  .form-outer {
    width: 40em;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 3em auto;

    .form-outer-heading {
      margin-top: 3em;

      >h1 {
        font-size: 2.25em;
        margin: 0;
      }
    }

    .form-inner {
      margin: 2em 0;
      border: 2px solid #e9e9e9;


      padding: 1em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      >h4 {
        margin: 0;
        margin-bottom: 0.5em;
        color: #484848;
        margin-top: 1em;
      }

      >input {
        font-size: 1.5em;
        border: 0;
        border-bottom: 1px solid #e9e9e9;
        width: 90%;
        outline: none;
      }

      textarea {
        outline: none;
      }

      button {
        width: 70%;
        margin: 1em auto;
        padding: 0.5em;
        border-radius: 4px;
        border: 0;
        background: linear-gradient(to bottom, #204191, #092262);
        color: white;
        font-size: 1.25em;

        &.disabled {
          background: #989898;
        }
      }
    }
  }
}
@import '../common/mixin.scss';

.scr13 {
  .pp-cir {
    position: relative;

    .circ {
      border-radius: 50%;
      border: 2px solid white;
      width: 2em;
      height: 2em;
      position: absolute;
      top: -1em;

      &:last-child {
        right: -4em;
      }

      &:first-child {
        left: -4em;
      }
    }

    .pp {
      border-color: white;
      border-width: 2px;
    }

  }

  .screen13-main {
    font-size: .5em;

    .heading {
      font-size: .5em;
      text-align: center;
      color: white;
      text-align: center;
      background-image: url("../assets/media/screen-13/bg.png");
      background-position: center;
      background-size: cover;
      padding: 5em;

      .heading-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 12em;
          margin: 1em 0;
          font-weight: 100;
          letter-spacing: 2px;
        }

        >div {
          width: 50%;
          margin: 3em;

          h2 {
            font-size: 7em;
          }
        }

      }
    }

    .screen13-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;




      .first-div {
        width: 60%;
        font-size: 2.25em;
        margin: 5em 0;
      }

      .content-div {
        display: flex;
        flex-direction: column;
        margin: 2em 0;
        width: 100%;

        >div:first-child {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .p45div {
          width: 60%;
          align-self: center;
          font-size: 2.5em;
        }
      }

      @media #{$tab-screens} {
        font-size: 2em;

        .first-div,
        .content-div .p45div {
          width: 85%;
        }
      }

      @media #{$small-screens} {
        font-size: 2.25em;
      }

    }
  }

}
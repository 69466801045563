@import "../common/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.landing {
  text-align: center;
  height: 100vh;
  background-size: contain;
  font-family: "Noto Sans", "sans-serif";

  .app-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .app-content {
    text-align: center;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center; // Center the content vertically
    align-items: center;
    font-weight: bold;
    font-size: 1.7em;
    padding-bottom: 10em; // Add space at the bottom to prevent overlap

    div:last-child {
      text-decoration: underline;
      font-size: 1.5em;
      margin-top: 1em;
    }
  }


  .my-main-button {
    max-width: 45rem;
    min-width: 25rem;
    height: 3em;
    font-size: 1.5em;
    border-radius: 16px;
    cursor: pointer;
    z-index: 10;
  }

  .app-learn-more {
    height: calc(70vh - 7.2em);
    display: flex;
    justify-content: center;
    padding: 0 6em;

    .pm-wani-exp {
      font-size: 4.2em;
      font-weight: bold;
    }

    @include yt-video-mixin(45em);

    .yt-video-sub {
      text-align: left;
      font-weight: bold;
      margin: auto;
    }

    > div {
      margin: auto 2em;

      &:last-child {
        width: 45%;
        text-align: left;
      }
    }

    .this-scheme {
      color: #214294;
      font-size: 1.7em;
      font-weight: bold;
      margin-top: 0.8em;
      position: relative;
      line-height: 1.5;
      padding-right: 1em;

      &:before,
      &:after {
        content: '"';
        position: absolute;
        color: #f4693a;
        font-size: 2em;
      }

      &:before {
        top: -2em;
        left: -1em;
      }

      &:after {
        bottom: -3em;
      }
    }
  }

  .app-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #3f3249;
    position: absolute;
    padding: 1em 2em 12em 2em;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    font-family: "Noto Sans", "sans-serif";
    z-index: 1;

    .butt {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0.8em 0 0.8em 0;
      justify-content: center;
      text-decoration: none;

      button.primary {
        white-space: nowrap;
        font-family: "Noto Sans", "sans-serif";
      }

      .g-cir-img {
        margin: 1.5em auto;
        @include g-cir-img-mixin(5em, 4em);
      }
    }

    > div {
      margin: auto 0;
    }

    .mammi-papa-div {
      color: white;
      max-width: 28%;
    }

    .mummy_ka {
      display: block;
      text-align: left;
      font-family: "Noto Sans", sans-serif;
    }

    .spacing-fix {
      margin-top: 10px;
    }

    .black-strip {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #161021;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:1.5em;
      box-sizing: border-box;

      .terms-of-service {
        color: white;
        // text-base;
        font-weight: bold;
        font-family: "Noto Sans", sans-serif;
        text-decoration: underline;
        cursor: pointer;
      }

      .copyright-text {
        color: #a7a1b2;
        font-size: 1em; // Equivalent to text-base
        font-weight: normal;
        font-family: 'Noto Sans', sans-serif;
        text-align: left;
      }
    }
  }

  .popup {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    font-family: "Noto Sans", "sans-serif";

    .backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: black;
      opacity: 0.2;
    }
    .popup_div1 {
      padding: 4em;
      text-align: left;
      font-family: "Noto Sans", "sans-serif";
      position: relative;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
      background-color: #d92b90;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      border-radius: 1em;
      max-width: 60%;
      font-size: 1.15em;
    }
    .popup_div2 {
      padding: 4em;
      text-align: left;
      font-family: "Noto Sans", "sans-serif";
      position: relative;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
      background-color: white;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      border-radius: 1em;
      max-width: 60%;
      font-size: 1.15em;
      align-items: center;
    }
    .btn {
      background-color: #d92b90;
      color: white;
      border: none;
      border-radius: 1em;
      padding: 1em 5em;
      cursor: pointer;
    }
  }
  .city-img-div-class {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    height: auto;
  }

  .popup-hide-item {
    display: block;
  }

  .city-img-class {
    height: 100%;
    max-width: 50em;
  }

  .popup1-title-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .popup-title-text {
    color: rgba(0, 0, 0, 0.7);
    font-size: 3em;
    margin-bottom: 1em;
    white-space: nowrap;
    text-align: center;
  }

  .popup1-title-1 {
    color: white;
    font-size: 3em;
    font-weight: 600;
    margin-bottom: "0.4em";
    text-align: left;
  }

  .popup1-play-store-title {
    display: flex;
    justify-content: center;
    font-size: 2em;
    font-weight: 600;
    color: #444444;
    margin-top: 1em;
  }

  .popup1-play-store-div {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    height: 18em;
    width: 100%;
    border-radius: 0px 0px 1em 1em;
  }

}

@media (max-width: 767px) {
  .landing {
    background-size: cover;

    .app-content {
      padding-bottom: 15em; // Increase space at the bottom

      font-size: 1.5em;
      height: 25%;
    }

    .app-learn-more {
      flex-direction: column;
      margin: auto;
      line-height: 1.5;
      font-size: 0.8em;
      padding: 0 1em;

      .yt-wrapper {
        margin: 0 auto;
      }
    }

    .my-main-button {
      max-width: 45rem;
      min-width: 25rem;
      height: 3.5em;
      font-size: 2em;
      border-radius: 16px;
      cursor: pointer;
    }

    .app-bottom {
      flex-direction: column;
      font-size: 0.8em;
      min-height: 40em;
      height: auto;
      bottom: -7.66em;
      text-align: center;
      align-items: center;
      padding: 2em 2em 16em 2em;

      .butt {
        margin: auto;
      }

      > div:not(:last-child) {
        margin-bottom: 2em;
      }

      > div:nth-child(2) {
        font-size: 1.2em;
        margin-bottom: 2em;
      }

      .mammi-papa-div {
        margin-top: 1em;
        justify-content: center;
        max-width: 90%;
      }

      .mummy_ka {
        display: block;
        width: 100%;
        margin: auto;
        text-align: center;
      }

      .mobile-fix {
        margin-right: 0.9em;
      }

      .spacing-fix {
        margin-top: 2.05em;
      }

      .black-strip {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #161021;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        .terms-of-service {
          color: white;
          font-weight: bold;
          font-family: "Noto Sans", sans-serif;
          text-decoration: underline;
          cursor: pointer;
        }

        .copyright-text {
          color: #a7a1b2;
          font-size: 2em;
          font-weight: normal;
          font-family: 'Noto Sans', sans-serif;
          text-align: left;
        }
      }
    }

    .popup {
      .popup_div {
        font-size: 0.75em;
      }
    }

    .popup_div2 {
      max-width: 90%;
      font-size: 0.5em;
    }

    .popup1-title-div {
      flex-direction: column;
    }

    .popup_div1 {
      padding: 2em;
      max-width: 90%;
      font-size: 0.5em;
      text-align: center;
    }
    .popup-hide-item {
      display: none;
    }

    .popup1-title-1 {
      color: white;
      font-size: 3em;
      font-weight: 600;
      margin-bottom: "0.4em";
      text-align: center;
    }

    .popup1-play-store-title {
      font-size: 1.5em;
      margin-top: 1em;
    }

    .popup1-play-store-div {
      height: 14em;
    }

    // .city-img-div-class{
    //   margin-bottom: -10%;
    // }

    // .city-img-class{
    //   height: 60%;
    //   max-width: 50em;
    // }

    .popup-title-text {
      color: rgba(0, 0, 0, 0.7);
      font-size: 3em;
      margin-bottom: 1em;
      white-space: wrap;
      text-align: center;
    }
  }
}

@media (max-width: 540px) {
  .landing {
    font-size: 0.7em;

    .popup-title-text {
      color: rgba(0, 0, 0, 0.7);
      font-size: 3em;
      margin-bottom: 1em;
      white-space: wrap;
      text-align: center;
    }

    .popup_div1 {
      padding: 2em;
      max-width: 90%;
      font-size: 0.5em;
      text-align: center;
    }

    // .city-img-class{
    //   height: 40%;
    //   max-width: 50em;
    // }
  }
}

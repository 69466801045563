@import '../common/mixin.scss';


.learning-center {
  .check-status {
    text-align: center;

    .check-status-box {
      margin-top: 25%;
      width: 33%;
      margin: 0px auto;
    }

    .title {
      text-align: left;
      font-size: 4em;
      font-weight: 800;
      display: block;
    }

    .or {
      text-align: center;
      font-size: 2em;
      display: block;
      margin-top: 10px;
    }

    .input {
      font-size: 3em;
      padding: 0.8em 1em 0.8em 1em;
      font-weight: 600;
      border: solid 3px black;
      border-radius: 5px;
      text-decoration: none;
      transition: ease-in-out, border .2s ease-in-out;
    }

    .input:focus {
      border: solid 5px black;
    }

    .check-now {
      padding: 0.8em;

    }
  }

  .dot {
    height: 5em;
    width: 5em;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    background-color: #838383;

    img {
      width: 2.2em;
      margin: 0 auto;
    }
  }

  .tile-container {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 95%;
    margin: auto;

    .tiles {
      width: 100%;
      height: 13em;
      display: flex;
      justify-content: center;
      z-index: 1;

      .lrncen-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        object-fit: cover;
        filter: grayscale(1);
      }

      .tile {
        cursor: pointer;
        text-decoration: none;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        background: white;
        outline: none;
        margin: auto 1em;
        position: relative;
        height: 11em;
        width: 11em;
        padding: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        span {
          font-size: 1.5em;
          height: 1.5em;
          margin: 0 auto;
          text-align: center;
          font-weight: bold;
          color: #838383;
        }

        &.select.pdo .dot {
          background-color: #F4693A;
        }

        &.select.pdo span {
          color: #F4693A;
        }

        &.select.partner .dot {
          background-color: #0839FB;
        }

        &.select.partner span {
          color: #0839FB;
        }
      }
    }

    @media #{$small-screens} {
      width: 100%;
      font-size: .6rem;
    }
  }

  .lc-card {
    border: 3px solid whitesmoke;
    margin: 2rem auto;
    width: 95%;

    .head {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      font-size: 1.5rem;
    }

    .content {
      padding: 1rem;

      .row {
        display: flex;
        justify-content: space-around;
      }
    }

    &.pdo a {
      color: rgb(244, 105, 58);
    }

    &.partner a {
      color: #0839FB;
    }
  }


  #blogs {
    .content {
      .row {
        margin-bottom: 4rem;

        .blog-card {
          width: 40em;
          display: flex;
          flex-direction: column;
          margin: auto;

          h1 {
            font-size: 3em;
            margin: 0;
          }

          h2 {
            font-size: 2em;
            font-weight: normal;
            margin: 0px;
            color: rgb(109, 107, 107);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          img {
            width: 100%;
          }

          a {

            font-size: 1.5em;
            text-decoration: none;
            font-weight: bold;
          }
        }

        @media #{$small-screens} {
          flex-direction: column;
          font-size: .9rem;
          margin-bottom: 1rem;

          .blog-card {
            margin-bottom: 2em;
          }
        }

        @media #{$very-small-screens} {
          font-size: .75rem;
        }
      }
    }
  }

  #knowledge_sharing {
    .content {
      .row {
        padding: 0 6rem;
        margin-bottom: 4rem;
        justify-content: space-between;

        .yt-wrapper {
          .yt-video-sub {
            font-size: 2.5rem;
            margin-top: .5em;
          }
        }
      }

      .row-1 {
        >div:first-child {
          display: flex;

          h1 {
            font-size: 4em;
            margin: auto 0;
            width: 75%;
          }
        }

        @include yt-video-mixin(35em);

        .yt-wrapper {
          .yt-video-sub {
            font-size: 2.5em;
            margin-top: .5em;
            color: #6d6b6b;
          }
        }
      }

      .row-2 {
        @include yt-video-mixin(30em);

        .yt-wrapper {
          .yt-video-sub {
            font-size: 2em;
            margin-top: .5em;
          }
        }
      }
    }

    @media #{$small-screens} {
      .content {
        .row {
          padding: 0;
          flex-direction: column;

          .yt-wrapper {
            margin: 2em auto;
          }
        }

        .row-1 {
          margin-bottom: 0;

          >div:first-child {
            h1 {
              font-size: 2em;
              margin: 0 auto 2rem auto;
              text-align: center;
            }
          }

          @include yt-video-mixin(30em);

          .yt-wrapper {
            .yt-video-sub {
              font-size: 2.1em;
              margin-top: .5em;
            }
          }
        }
      }
    }
  }

  #how_to_guides {
    .content {
      .row {
        >div {
          margin: auto;
          padding: 1em;
        }

        @include yt-video-mixin(35em);

        .yt-wrapper {
          .yt-video-sub {
            font-size: 2em;
            font-weight: bold;
          }
        }

        @media #{$small-screens} {
          flex-direction: column;

          >div {
            padding: 0;
            margin-bottom: 2em;
          }
        }

        @media #{$mid-small-screens} {
          font-size: .9rem;
        }

        @media #{$very-small-screens} {
          font-size: .7rem;
        }
      }
    }
  }

  #in-news {
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .news-card {
        width: 40em;
        display: flex;
        margin: 3em;

        h1 {
          font-size: 1.5em;
        }

        h2 {
          font-size: 1.25em;
        }

        img {
          width: 12em;
          height: 12em;
          margin: auto;
          object-fit: contain;
        }
      }

      @media #{$tab-screens} {
        font-size: .9rem;

      }

      @media #{$small-screens} {
        flex-direction: column;

        .news-card {
          width: 100%;
          margin: 0;
        }
      }

      @media #{$mid-small-screens} {
        font-size: .8rem;
      }

      @media #{$very-small-screens} {
        font-size: .7rem;
      }
    }
  }

  #faqs {
    .content {
      display: flex;
      flex-direction: column;
      font-size: 2em;

      @media #{$small-screens} {
        font-size: 1.4rem;
      }
    }

    .FAQs-number {
      padding: 2em;
      margin-left: 10%;
      font-size: 1em;

      h1 {
        margin: 0;
      }
    }
  }
}
@import "../common/mixin.scss";

.panel {
  display: block;
  background-color: white;
  overflow: hidden;
  transition: height 0.5s ease-in;
}

.plan-title {
  > img {
    margin: auto 1em;
    width: 1em;
    &.arrow {
      height: 1.5em;
      margin: auto 0;
    }
  }
}

.feature-heading {
  font-size: 2em;
  font-weight: lighter;
}

@media #{$small-screens} {
  .plan-title {
    > img {
      width: 0.5em;
      height: 0.5em;
      margin: auto 0.5em;
    }
  }

  .feature-heading {
    font-size: 0.1em;
    font-weight: normal;
  }
}
